//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { subject } from '@casl/ability';
import { mapState } from 'vuex';

export default {
    data() {
        const result = {
            Users: subject('User', {}),
            Centers: subject('Center', {}),
            Studies: subject('Study', {}),
            SideEffects: subject('SideEffect', {}),
            Medications: subject('Medication', {}),
            SaeReports: subject('SaeReport', {}),
            Manufacturers: subject('Manufacturer', {}),
        };
        return {
            ...result,
            showAdmin: Object.values([
                result.Centers,
                result.Users,
                result.Studies,
                result.SideEffects,
                result.Medications,
                result.Manufacturers,
            ]).some(Target => this.$ability.can('read', Target)),
            showEvaluation: Object.values([
                result.Centers,
            ]).some(Target => this.$ability.can('read', Target)),
        };
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
            breadcrumbs: state => state.breadcrumbs,
        }),
        centers() {
            return this.user.centerMemberships.nodes.map(node => node.center);
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch('auth/logout');
            this.$router.push({ name: 'auth-login' });
        },
    },
};
