import Vue from 'vue';
import { abilitiesPlugin, Can } from '@casl/vue';
import { Ability } from '@casl/ability';

const ability = new Ability([], {
    detectSubjectType: obj => {
        return (obj && obj.__typename) || obj;
    },
});

Vue.use(abilitiesPlugin, ability);
Vue.component('Can', Can);

export default ({ store }, inject) => {
    inject('ability', ability);
    store.subscribe((mutation) => {
        switch (mutation.type) {
            case 'auth/loggedIn':
                ability.update(mutation.payload.user.abilityRules);
                break;
            case 'auth/loggedOut':
                ability.update([]);
                break;
        }
    });
};
