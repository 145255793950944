

export const state = () => ({
    initialized: false,
    breadcrumbs: [],
});


export const getters = {
    homeRoute(state) {
        if (!state.auth.authenticated) {
            return { name: 'auth-login' };
        }
        const { user } = state.auth;
        if (user.centers.length > 0) {
            return { name: 'patients' };
        }
        if (user.isAdmin) {
            return { name: 'users' };
        }
        return { name: 'users-detail', params: { userId: user._id } };
    },
};


export const mutations = {
    initialized(state, data) {
        state.initialized = true;
    },
    breadcrumbsUpdated(state, breadcrumbs) {
        state.breadcrumbs = breadcrumbs.length ? breadcrumbs : [{ text: '...' }];
    },
};


export const actions =
{
    async initialize({ state, dispatch, commit }) {
        if (state.initialized) {
            return;
        }
        // this.app.router.onReady(() => {
        //     dispatch('updateBreadcrumbs');
        // });
        this.app.router.afterEach(() => {
            dispatch('updateBreadcrumbs');
        });
        await dispatch('auth/initialize', null, { root: true });
        commit('initialized');
    },
    updateBreadcrumbs({ commit }) {
        const breadcrumbs = [];
        for (const match of this.app.router.currentRoute.matched) {
            const instance = match.instances.default;
            if (!instance) {
                continue;
            }
            if (instance.breadcrumbs) {
                breadcrumbs.push(...instance.breadcrumbs());
            }
        }
        commit('breadcrumbsUpdated', breadcrumbs);
    },
};
