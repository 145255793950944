import Vue from 'vue';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';

import 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism-coy.css';

import '@/lib/sexpr/language';


Vue.component('PrismEditor', PrismEditor);
