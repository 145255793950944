import { CreateSurveys, UpdateSurvey, DeleteSurvey, FinishSurvey, ApproveSaeReportsForSurvey } from '~/apollo/survey.gql';


export const actions = {
    create({ dispatch }, input) {
        return dispatch('apollo/mutate', {
            mutation: CreateSurveys,
            variables: { input },
        }, { root: true });
    },
    update({ dispatch }, { _id, ...input }) {
        return dispatch('apollo/mutate', {
            mutation: UpdateSurvey,
            variables: { _id, input },
        }, { root: true });
    },
    delete({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: DeleteSurvey,
            variables: { _id },
        }, { root: true });
    },
    finish({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: FinishSurvey,
            variables: { _id },
        }, { root: true });
    },
    approveSaeReports({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: ApproveSaeReportsForSurvey,
            variables: { _id },
        }, { root: true });
    },
};
