import { CreateSideEffectCategory, UpdateSideEffectCategory, DeleteSideEffectCategory } from '~/apollo/side-effect-category.gql';


export const actions = {
    create({ dispatch }, record) {
        return dispatch('apollo/mutate', {
            mutation: CreateSideEffectCategory,
            variables: { record },
        }, { root: true });
    },
    update({ dispatch }, { _id, ...record }) {
        return dispatch('apollo/mutate', {
            mutation: UpdateSideEffectCategory,
            variables: { _id, record },
        }, { root: true });
    },
    delete({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: DeleteSideEffectCategory,
            variables: { _id },
        }, { root: true });
    },
};
