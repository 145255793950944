import { CreatePatient, UpdatePatient, DeletePatient, ResetPatientAccount, DisablePatientAccount, DisablePatientSurvey } from '~/apollo/patient.gql';


export const actions = {
    create({ dispatch }, record) {
        return dispatch('apollo/mutate', {
            mutation: CreatePatient,
            variables: { record },
        }, { root: true });
    },
    update({ dispatch }, { _id, ...record }) {
        return dispatch('apollo/mutate', {
            mutation: UpdatePatient,
            variables: { _id, record },
        }, { root: true });
    },
    delete({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: DeletePatient,
            variables: { _id },
        }, { root: true });
    },
    disableSurvey({ dispatch }, { _id, ...input }) {
        return dispatch('apollo/mutate', {
            mutation: DisablePatientSurvey,
            variables: { _id, input },
        }, { root: true });
    },
    resetAccount({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: ResetPatientAccount,
            variables: { _id },
        }, { root: true });
    },
    disableAccount({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: DisablePatientAccount,
            variables: { _id },
        }, { root: true });
    },
};
