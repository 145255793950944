import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _368b5adc = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _9a19d152 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _6bc6f067 = () => interopDefault(import('../pages/auth/recover.vue' /* webpackChunkName: "pages/auth/recover" */))
const _cfdcdcdc = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _33fe1372 = () => interopDefault(import('../pages/centers/index.vue' /* webpackChunkName: "pages/centers/index" */))
const _72e89635 = () => interopDefault(import('../pages/external.vue' /* webpackChunkName: "pages/external" */))
const _3717e62b = () => interopDefault(import('../pages/external/patient.vue' /* webpackChunkName: "pages/external/patient" */))
const _80dea26a = () => interopDefault(import('../pages/manufacturers/index.vue' /* webpackChunkName: "pages/manufacturers/index" */))
const _5345a98a = () => interopDefault(import('../pages/medications.vue' /* webpackChunkName: "pages/medications" */))
const _59b33b7b = () => interopDefault(import('../pages/patients/index.vue' /* webpackChunkName: "pages/patients/index" */))
const _5834e376 = () => interopDefault(import('../pages/side-effects.vue' /* webpackChunkName: "pages/side-effects" */))
const _01a21db8 = () => interopDefault(import('../pages/site-notice.vue' /* webpackChunkName: "pages/site-notice" */))
const _3b5929e6 = () => interopDefault(import('../pages/statistics.vue' /* webpackChunkName: "pages/statistics" */))
const _66d6547a = () => interopDefault(import('../pages/statistics/detail.vue' /* webpackChunkName: "pages/statistics/detail" */))
const _622c6303 = () => interopDefault(import('../pages/statistics/patient.vue' /* webpackChunkName: "pages/statistics/patient" */))
const _0fddb858 = () => interopDefault(import('../pages/statistics/question.vue' /* webpackChunkName: "pages/statistics/question" */))
const _3ba48f68 = () => interopDefault(import('../pages/statistics/survey.vue' /* webpackChunkName: "pages/statistics/survey" */))
const _68319ff5 = () => interopDefault(import('../pages/statistics2.vue' /* webpackChunkName: "pages/statistics2" */))
const _55564f20 = () => interopDefault(import('../pages/studies/index.vue' /* webpackChunkName: "pages/studies/index" */))
const _6ac17622 = () => interopDefault(import('../pages/surveys/index.vue' /* webpackChunkName: "pages/surveys/index" */))
const _941919b4 = () => interopDefault(import('../pages/uaw-reports/index.vue' /* webpackChunkName: "pages/uaw-reports/index" */))
const _c91b56de = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _7270e22c = () => interopDefault(import('../pages/centers/detail.vue' /* webpackChunkName: "pages/centers/detail" */))
const _36611237 = () => interopDefault(import('../pages/centers/detail/api.vue' /* webpackChunkName: "pages/centers/detail/api" */))
const _0eb77e75 = () => interopDefault(import('../pages/centers/detail/core-data.vue' /* webpackChunkName: "pages/centers/detail/core-data" */))
const _0caf6088 = () => interopDefault(import('../pages/computed-values/detail.vue' /* webpackChunkName: "pages/computed-values/detail" */))
const _f4be8c5e = () => interopDefault(import('../pages/computed-values/detail/core-data.vue' /* webpackChunkName: "pages/computed-values/detail/core-data" */))
const _2d8bc46a = () => interopDefault(import('../pages/computed-values/detail/definition.vue' /* webpackChunkName: "pages/computed-values/detail/definition" */))
const _6a4f8bb0 = () => interopDefault(import('../pages/manufacturers/detail.vue' /* webpackChunkName: "pages/manufacturers/detail" */))
const _c783e91e = () => interopDefault(import('../pages/manufacturers/detail/core-data.vue' /* webpackChunkName: "pages/manufacturers/detail/core-data" */))
const _75074378 = () => interopDefault(import('../pages/patients/detail.vue' /* webpackChunkName: "pages/patients/detail" */))
const _5c6e6b96 = () => interopDefault(import('../pages/patients/detail/account.vue' /* webpackChunkName: "pages/patients/detail/account" */))
const _a36e408e = () => interopDefault(import('../pages/patients/detail/active-surveys.vue' /* webpackChunkName: "pages/patients/detail/active-surveys" */))
const _296db0c1 = () => interopDefault(import('../pages/patients/detail/core-data.vue' /* webpackChunkName: "pages/patients/detail/core-data" */))
const _4d2b2fc2 = () => interopDefault(import('../pages/patients/detail/surveys.vue' /* webpackChunkName: "pages/patients/detail/surveys" */))
const _aea45ae6 = () => interopDefault(import('../pages/patients/detail/tablet.vue' /* webpackChunkName: "pages/patients/detail/tablet" */))
const _9d3e10d0 = () => interopDefault(import('../pages/questionaires/detail.vue' /* webpackChunkName: "pages/questionaires/detail" */))
const _6b2b54be = () => interopDefault(import('../pages/questionaires/detail/commit.vue' /* webpackChunkName: "pages/questionaires/detail/commit" */))
const _1666863e = () => interopDefault(import('../pages/questionaires/detail/core-data.vue' /* webpackChunkName: "pages/questionaires/detail/core-data" */))
const _55cb13d1 = () => interopDefault(import('../pages/questionaires/detail/preview.vue' /* webpackChunkName: "pages/questionaires/detail/preview" */))
const _13bd1c28 = () => interopDefault(import('../pages/questionaires/detail/schema.vue' /* webpackChunkName: "pages/questionaires/detail/schema" */))
const _9b400f3c = () => interopDefault(import('../pages/questionaires/detail/versions.vue' /* webpackChunkName: "pages/questionaires/detail/versions" */))
const _0fc6ed30 = () => interopDefault(import('../pages/studies/admin/index.vue' /* webpackChunkName: "pages/studies/admin/index" */))
const _6d994523 = () => interopDefault(import('../pages/studies/detail.vue' /* webpackChunkName: "pages/studies/detail" */))
const _b479c59e = () => interopDefault(import('../pages/studies/detail/accounting.vue' /* webpackChunkName: "pages/studies/detail/accounting" */))
const _7469aa61 = () => interopDefault(import('../pages/studies/detail/questions.vue' /* webpackChunkName: "pages/studies/detail/questions" */))
const _f47f449e = () => interopDefault(import('../pages/surveys/detail.vue' /* webpackChunkName: "pages/surveys/detail" */))
const _225b2e47 = () => interopDefault(import('../pages/surveys/detail/overview.vue' /* webpackChunkName: "pages/surveys/detail/overview" */))
const _ba0349e2 = () => interopDefault(import('../pages/surveys/detail/questionaire.vue' /* webpackChunkName: "pages/surveys/detail/questionaire" */))
const _be63fda6 = () => interopDefault(import('../pages/uaw-reports/detail.vue' /* webpackChunkName: "pages/uaw-reports/detail" */))
const _15e4b608 = () => interopDefault(import('../pages/uaw-reports/detail/data.vue' /* webpackChunkName: "pages/uaw-reports/detail/data" */))
const _c014a02c = () => interopDefault(import('../pages/uaw-reports/detail/protocol.vue' /* webpackChunkName: "pages/uaw-reports/detail/protocol" */))
const _36d46a02 = () => interopDefault(import('../pages/uaw-reports/settings.vue' /* webpackChunkName: "pages/uaw-reports/settings" */))
const _6b2b4d22 = () => interopDefault(import('../pages/users/detail.vue' /* webpackChunkName: "pages/users/detail" */))
const _a68da42a = () => interopDefault(import('../pages/users/detail/core-data.vue' /* webpackChunkName: "pages/users/detail/core-data" */))
const _835b2ae0 = () => interopDefault(import('../pages/users/detail/roles.vue' /* webpackChunkName: "pages/users/detail/roles" */))
const _a9a8665e = () => interopDefault(import('../pages/value-lists/detail.vue' /* webpackChunkName: "pages/value-lists/detail" */))
const _29bd1eda = () => interopDefault(import('../pages/value-lists/detail/core-data.vue' /* webpackChunkName: "pages/value-lists/detail/core-data" */))
const _69196afc = () => interopDefault(import('../pages/value-lists/detail/items.vue' /* webpackChunkName: "pages/value-lists/detail/items" */))
const _fd2c6f3a = () => interopDefault(import('../pages/studies/admin/detail.vue' /* webpackChunkName: "pages/studies/admin/detail" */))
const _7bf35992 = () => interopDefault(import('../pages/studies/admin/detail/centers.vue' /* webpackChunkName: "pages/studies/admin/detail/centers" */))
const _3fdd4694 = () => interopDefault(import('../pages/studies/admin/detail/computed-values.vue' /* webpackChunkName: "pages/studies/admin/detail/computed-values" */))
const _3d6b4bec = () => interopDefault(import('../pages/studies/admin/detail/core-data.vue' /* webpackChunkName: "pages/studies/admin/detail/core-data" */))
const _161f19b4 = () => interopDefault(import('../pages/studies/admin/detail/questionaires.vue' /* webpackChunkName: "pages/studies/admin/detail/questionaires" */))
const _55b6ed02 = () => interopDefault(import('../pages/studies/admin/detail/statistics.vue' /* webpackChunkName: "pages/studies/admin/detail/statistics" */))
const _3b9b99ad = () => interopDefault(import('../pages/studies/admin/detail/surveys.vue' /* webpackChunkName: "pages/studies/admin/detail/surveys" */))
const _1a5650e6 = () => interopDefault(import('../pages/studies/admin/detail/value-lists.vue' /* webpackChunkName: "pages/studies/admin/detail/value-lists" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _368b5adc,
    name: "auth",
    children: [{
      path: "login",
      component: _8e1272a8,
      name: "auth-login"
    }, {
      path: "logout",
      component: _9a19d152,
      name: "auth-logout"
    }, {
      path: "recover",
      component: _6bc6f067,
      name: "auth-recover"
    }, {
      path: "reset",
      component: _cfdcdcdc,
      name: "auth-reset"
    }]
  }, {
    path: "/centers",
    component: _33fe1372,
    name: "centers"
  }, {
    path: "/external",
    component: _72e89635,
    name: "external",
    children: [{
      path: "patient",
      component: _3717e62b,
      name: "external-patient"
    }]
  }, {
    path: "/manufacturers",
    component: _80dea26a,
    name: "manufacturers"
  }, {
    path: "/medications",
    component: _5345a98a,
    name: "medications"
  }, {
    path: "/patients",
    component: _59b33b7b,
    name: "patients"
  }, {
    path: "/side-effects",
    component: _5834e376,
    name: "side-effects"
  }, {
    path: "/site-notice",
    component: _01a21db8,
    name: "site-notice"
  }, {
    path: "/statistics",
    component: _3b5929e6,
    redirect: {"name":"statistics-survey"},
    name: "statistics",
    children: [{
      path: "detail",
      component: _66d6547a,
      name: "statistics-detail"
    }, {
      path: "patient",
      component: _622c6303,
      name: "statistics-patient"
    }, {
      path: "question",
      component: _0fddb858,
      name: "statistics-question"
    }, {
      path: "survey",
      component: _3ba48f68,
      name: "statistics-survey"
    }]
  }, {
    path: "/statistics",
    component: _68319ff5,
    redirect: {"name":"statistics-survey"},
    name: "statistics2"
  }, {
    path: "/studies",
    component: _55564f20,
    name: "studies"
  }, {
    path: "/surveys",
    component: _6ac17622,
    name: "surveys"
  }, {
    path: "/uaw-reports",
    component: _941919b4,
    name: "uaw-reports"
  }, {
    path: "/users",
    component: _c91b56de,
    name: "users"
  }, {
    path: "/centers/:centerId",
    component: _7270e22c,
    redirect: {"name":"centers-detail-core-data"},
    name: "centers-detail",
    children: [{
      path: "api",
      component: _36611237,
      name: "centers-detail-api"
    }, {
      path: "core-data",
      component: _0eb77e75,
      name: "centers-detail-core-data"
    }]
  }, {
    path: "/computed-values/:computedValueId",
    component: _0caf6088,
    redirect: {"name":"computed-values-detail-definition"},
    name: "computed-values-detail",
    children: [{
      path: "core-data",
      component: _f4be8c5e,
      name: "computed-values-detail-core-data"
    }, {
      path: "definition",
      component: _2d8bc46a,
      name: "computed-values-detail-definition"
    }]
  }, {
    path: "/manufacturers/:manufacturerId",
    component: _6a4f8bb0,
    redirect: {"name":"manufacturers-detail-core-data"},
    name: "manufacturers-detail",
    children: [{
      path: "core-data",
      component: _c783e91e,
      name: "manufacturers-detail-core-data"
    }]
  }, {
    path: "/patients/:patientId",
    component: _75074378,
    redirect: {"name":"patients-detail-surveys"},
    name: "patients-detail",
    children: [{
      path: "account",
      component: _5c6e6b96,
      name: "patients-detail-account"
    }, {
      path: "active-surveys",
      component: _a36e408e,
      name: "patients-detail-active-surveys"
    }, {
      path: "core-data",
      component: _296db0c1,
      name: "patients-detail-core-data"
    }, {
      path: "surveys",
      component: _4d2b2fc2,
      name: "patients-detail-surveys"
    }, {
      path: "tablet",
      component: _aea45ae6,
      name: "patients-detail-tablet"
    }]
  }, {
    path: "/questionaires/:questionaireId",
    component: _9d3e10d0,
    redirect: {"name":"questionaires-detail-schema"},
    name: "questionaires-detail",
    children: [{
      path: "commit",
      component: _6b2b54be,
      name: "questionaires-detail-commit"
    }, {
      path: "core-data",
      component: _1666863e,
      name: "questionaires-detail-core-data"
    }, {
      path: "preview",
      component: _55cb13d1,
      name: "questionaires-detail-preview"
    }, {
      path: "schema",
      component: _13bd1c28,
      name: "questionaires-detail-schema"
    }, {
      path: "versions",
      component: _9b400f3c,
      name: "questionaires-detail-versions"
    }]
  }, {
    path: "/studies/admin",
    component: _0fc6ed30,
    name: "studies-admin"
  }, {
    path: "/studies/:studyId",
    component: _6d994523,
    redirect: {"name":"studies-detail-questions"},
    name: "studies-detail",
    children: [{
      path: "accounting",
      component: _b479c59e,
      name: "studies-detail-accounting"
    }, {
      path: "questions",
      component: _7469aa61,
      name: "studies-detail-questions"
    }]
  }, {
    path: "/surveys/:surveyId",
    component: _f47f449e,
    redirect: {"name":"surveys-detail-overview"},
    name: "surveys-detail",
    children: [{
      path: "overview",
      component: _225b2e47,
      name: "surveys-detail-overview"
    }, {
      path: "/surveys/:surveyId/questionaire/:questionaireId",
      component: _ba0349e2,
      name: "surveys-detail-questionaire"
    }]
  }, {
    path: "/uaw-reports/:uawReportId",
    component: _be63fda6,
    redirect: {"name":"uaw-reports-detail-data"},
    name: "uaw-reports-detail",
    children: [{
      path: "data",
      component: _15e4b608,
      name: "uaw-reports-detail-data"
    }, {
      path: "protocol",
      component: _c014a02c,
      name: "uaw-reports-detail-protocol"
    }]
  }, {
    path: "/uaw-reports/settings",
    component: _36d46a02,
    name: "uaw-reports-settings"
  }, {
    path: "/users/:userId",
    component: _6b2b4d22,
    redirect: {"name":"users-detail-core-data"},
    name: "users-detail",
    children: [{
      path: "core-data",
      component: _a68da42a,
      name: "users-detail-core-data"
    }, {
      path: "roles",
      component: _835b2ae0,
      name: "users-detail-roles"
    }]
  }, {
    path: "/value-lists/:valueListId",
    component: _a9a8665e,
    redirect: {"name":"value-lists-detail-items"},
    name: "value-lists-detail",
    children: [{
      path: "core-data",
      component: _29bd1eda,
      name: "value-lists-detail-core-data"
    }, {
      path: "items",
      component: _69196afc,
      name: "value-lists-detail-items"
    }]
  }, {
    path: "/studies/:studyId/admin",
    component: _fd2c6f3a,
    redirect: {"name":"studies-admin-detail-questionaires"},
    name: "studies-admin-detail",
    children: [{
      path: "centers",
      component: _7bf35992,
      name: "studies-admin-detail-centers"
    }, {
      path: "computed-values",
      component: _3fdd4694,
      name: "studies-admin-detail-computed-values"
    }, {
      path: "core-data",
      component: _3d6b4bec,
      name: "studies-admin-detail-core-data"
    }, {
      path: "questionaires",
      component: _161f19b4,
      name: "studies-admin-detail-questionaires"
    }, {
      path: "statistics",
      component: _55b6ed02,
      name: "studies-admin-detail-statistics"
    }, {
      path: "surveys",
      component: _3b9b99ad,
      name: "studies-admin-detail-surveys"
    }, {
      path: "value-lists",
      component: _1a5650e6,
      name: "studies-admin-detail-value-lists"
    }]
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
