

export default {
    inject: ['registerField', 'deregisterField'],
    props: {
        name: { type: String, required: true },
        ignore: { type: Boolean, default: false },
        default: { default: null },
        skipEmpty: { type: Boolean, default: false },
    },
    data() {
        return {
            value: this.default,
            errors: null,
        };
    },
    computed: {
        state() {
            if (this.errors && this.errors.length) {
                return false;
            }
        },
    },
    watch: {
        value(value) {
            this.$emit('input', value);
        },
    },
    beforeMount() {
        this.registerField(this);
    },
    beforeDestroy() {
        this.deregisterField(this);
    },
    methods: {
        validate() {
            if (this.skipEmpty) {
                if (this.value === null) {
                    return undefined;
                }
                if (typeof this.value === 'string') {
                    const trimmed = this.value.trim();
                    return trimmed.length > 0 ? trimmed : undefined;
                }
            }
            return this.value;
        },
        setErrors(errors) {
            this.errors = errors;
        },
        setData(data) {
            if (!data || !(this.name in data)) {
                return;
            }
            this.setValue(this.readValue(data));
        },
        readValue(data) {
            return data && data[this.name];
        },
        setValue(newValue) {
            if (newValue !== this.value) {
                this.value = newValue;
                this.$emit('update:value', newValue);
            }
        },
    },
};
