//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NuxtBuildIndicator',
  data () {
    return {
      building: false,
      progress: 0,
      animatedProgress: 0,
      reconnectAttempts: 0
    }
  },
  computed: {
    options: () => ({"position":"bottom-right","backgroundColor":"#2E495E","color":"#00C48D"}),
    indicatorStyle () {
      const [d1, d2] = this.options.position.split('-')
      return {
        [d1]: '20px',
        [d2]: '20px',
        'background-color': this.options.backgroundColor,
        color: this.options.color
      }
    }
  },
  watch: {
    progress (val, oldVal) {
      // Average progress may decrease but ignore it!
      if (val < oldVal) {
        return
      }
      // Cancel old animation
      clearInterval(this._progressAnimation)
      // Jump to edge immediately
      if (val < 10 || val > 90) {
        this.animatedProgress = val
        return
      }
      // Animate to value
      this._progressAnimation = setInterval(() => {
        const diff = this.progress - this.animatedProgress
        if (diff > 0) {
          this.animatedProgress++
        } else {
          clearInterval(this._progressAnimation)
        }
      }, 50)
    }
  },
  mounted () {
    if (EventSource === undefined) {
      return // Unsupported
    }
    this.sseConnect()
  },
  beforeDestroy () {
    this.sseClose()
    clearInterval(this._progressAnimation)
  },
  methods: {
    sseConnect () {
      if (this._connecting) {
        return
      }
      this._connecting = true
      this.sse = new EventSource('/_loading/sse')
      this.sse.addEventListener('message', event => this.onSseMessage(event))
    },
    onSseMessage (message) {
      const data = JSON.parse(message.data)
      if (!data.states) {
        return
      }

      this.progress = Math.round(data.states.reduce((p, s) => p + s.progress, 0) / data.states.length)

      if (!data.allDone) {
        this.building = true
      } else {
        this.$nextTick(() => {
          this.building = false
          this.animatedProgress = 0
          this.progress = 0
          clearInterval(this._progressAnimation)
        })
      }
    },

    sseClose () {
      if (this.sse) {
        this.sse.close()
        delete this.sse
      }
    }
  }
}
