var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "b-navbar",
          { attrs: { type: "dark", variant: "info" } },
          [
            _c("b-navbar-brand", [_vm._v("CEDUR 3.0 (TEST)")]),
            _c(
              "b-navbar-nav",
              [
                _c(
                  "can",
                  { attrs: { do: "read", on: "Patient" } },
                  [
                    _c("b-nav-item", { attrs: { to: { name: "patients" } } }, [
                      _vm._v("Patienten")
                    ])
                  ],
                  1
                ),
                _c(
                  "can",
                  { attrs: { do: "read", on: "Survey" } },
                  [
                    _c("b-nav-item", { attrs: { to: { name: "surveys" } } }, [
                      _vm._v("Befragungen")
                    ])
                  ],
                  1
                ),
                _c(
                  "can",
                  { attrs: { do: "read", on: _vm.SaeReports } },
                  [
                    _c(
                      "b-nav-item",
                      { attrs: { to: { name: "uaw-reports" } } },
                      [_vm._v("UAWs")]
                    )
                  ],
                  1
                ),
                _c(
                  "can",
                  { attrs: { do: "read", on: "Study" } },
                  [
                    _c("b-nav-item", { attrs: { to: { name: "studies" } } }, [
                      _vm._v("Studien")
                    ])
                  ],
                  1
                ),
                _vm.showAdmin
                  ? _c(
                      "b-nav-item-dropdown",
                      { attrs: { text: "Administration" } },
                      [
                        _c(
                          "can",
                          { attrs: { do: "read", on: _vm.Centers } },
                          [
                            _c(
                              "b-dropdown-item",
                              { attrs: { to: { name: "centers" } } },
                              [_vm._v("Zentren")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "can",
                          { attrs: { do: "read", on: _vm.Users } },
                          [
                            _c(
                              "b-dropdown-item",
                              { attrs: { to: { name: "users" } } },
                              [_vm._v("Benutzer")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "can",
                          { attrs: { do: "read", on: _vm.Studies } },
                          [
                            _c(
                              "b-dropdown-item",
                              { attrs: { to: { name: "studies-admin" } } },
                              [_vm._v("Studien")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "can",
                          { attrs: { do: "read", on: _vm.SideEffects } },
                          [
                            _c(
                              "b-dropdown-item",
                              { attrs: { to: { name: "side-effects" } } },
                              [_vm._v("Nebenwirkungen")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "can",
                          { attrs: { do: "read", on: _vm.Medications } },
                          [
                            _c(
                              "b-dropdown-item",
                              { attrs: { to: { name: "medications" } } },
                              [_vm._v("Medikamente")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "can",
                          { attrs: { do: "read", on: _vm.Manufacturers } },
                          [
                            _c(
                              "b-dropdown-item",
                              { attrs: { to: { name: "manufacturers" } } },
                              [_vm._v("Hersteller")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "b-navbar-nav",
              { staticClass: "ml-auto" },
              [
                _c(
                  "b-nav-item-dropdown",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function() {
                          return [_vm._v(_vm._s(_vm._f("username")(_vm.user)))]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        attrs: {
                          to: {
                            name: "users-detail",
                            params: { userId: _vm.user._id }
                          }
                        }
                      },
                      [_vm._v("Profil")]
                    ),
                    _c("b-dropdown-divider"),
                    _c(
                      "b-dropdown-item",
                      { attrs: { to: { name: "auth-logout" } } },
                      [_vm._v("Logout")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "body" }, [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("b-toaster", { staticClass: "toaster", attrs: { name: "app" } }),
          _c("b-breadcrumb", { attrs: { items: _vm.breadcrumbs } }),
          _c("nuxt")
        ],
        1
      )
    ]),
    _c("div", { staticClass: "footer" }, [
      _c("hr"),
      _c(
        "div",
        { staticClass: "text-center mb-3 small" },
        [
          _c(
            "b-link",
            { attrs: { to: { name: "site-notice" }, target: "_blank" } },
            [_vm._v("Impressum & Datenschutz")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }