import { CreateManufacturer, UpdateManufacturer, DeleteManufacturer } from '~/apollo/manufacturer.gql';


export const actions = {
    create({ dispatch }, record) {
        return dispatch('apollo/mutate', {
            mutation: CreateManufacturer,
            variables: { record },
        }, { root: true });
    },
    update({ dispatch }, { _id, ...record }) {
        return dispatch('apollo/mutate', {
            mutation: UpdateManufacturer,
            variables: { _id, record },
        }, { root: true });
    },
    delete({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: DeleteManufacturer,
            variables: { _id },
        }, { root: true });
    },
};
