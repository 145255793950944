import { ValidationError } from '~/lib/form';


export const mutations =
{
    mutateFailed(state, data) {
    },
    mutateSucceeded(state, data) {
    },
};


export const actions =
{
    async mutate({ commit }, params) {
        const apollo = this.app.apolloProvider.defaultClient;
        try {
            const result = await apollo.mutate(params);
            const data = result.data.result;
            if (data.error) {
                throw new ValidationError(data.error);
            }
            commit('mutateSucceeded', data);
            return data;
        } catch (error) {
            commit('mutateFailed', error);
            throw error;
        }
    },
    async query({ commit }, params) {
        const apollo = this.app.apolloProvider.defaultClient;
        const result = await apollo.query(params);
        return result.data;
    },
};
