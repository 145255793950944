import { DeleteTabletAccount } from '~/apollo/tablet-account.gql';


export const actions = {
    delete({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: DeleteTabletAccount,
            variables: { _id },
        }, { root: true });
    },
};
