import { CreateCenter, UpdateCenter, DeleteCenter, AssignStudyToCenter, EnableCenterApi, DisableCenterApi } from '~/apollo/center.gql';


export const actions = {
    create({ dispatch }, record) {
        return dispatch('apollo/mutate', {
            mutation: CreateCenter,
            variables: { record },
        }, { root: true });
    },
    update({ dispatch }, { _id, ...record }) {
        return dispatch('apollo/mutate', {
            mutation: UpdateCenter,
            variables: { _id, record },
        }, { root: true });
    },
    delete({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: DeleteCenter,
            variables: { _id },
        }, { root: true });
    },
    assignStudy({ dispatch }, { _id, ...input }) {
        return dispatch('apollo/mutate', {
            mutation: AssignStudyToCenter,
            variables: { _id, input },
        }, { root: true });
    },
    enableApi({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: EnableCenterApi,
            variables: { _id },
        }, { root: true });
    },
    disableApi({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: DisableCenterApi,
            variables: { _id },
        }, { root: true });
    },
};
