import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  if (typeof store === 'function') {
    return console.warn('Classic mode for store/ is deprecated and will be removed in Nuxt 3.')
  }

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/apollo.js'), 'apollo.js')
  resolveStoreModules(require('../store/auth.js'), 'auth.js')
  resolveStoreModules(require('../store/center.js'), 'center.js')
  resolveStoreModules(require('../store/computed-value.js'), 'computed-value.js')
  resolveStoreModules(require('../store/manufacturer.js'), 'manufacturer.js')
  resolveStoreModules(require('../store/medication-category.js'), 'medication-category.js')
  resolveStoreModules(require('../store/medication.js'), 'medication.js')
  resolveStoreModules(require('../store/patient.js'), 'patient.js')
  resolveStoreModules(require('../store/questionaire.js'), 'questionaire.js')
  resolveStoreModules(require('../store/sae-report.js'), 'sae-report.js')
  resolveStoreModules(require('../store/schema-node.js'), 'schema-node.js')
  resolveStoreModules(require('../store/side-effect-category.js'), 'side-effect-category.js')
  resolveStoreModules(require('../store/side-effect.js'), 'side-effect.js')
  resolveStoreModules(require('../store/study.js'), 'study.js')
  resolveStoreModules(require('../store/survey-def.js'), 'survey-def.js')
  resolveStoreModules(require('../store/survey.js'), 'survey.js')
  resolveStoreModules(require('../store/tablet-account.js'), 'tablet-account.js')
  resolveStoreModules(require('../store/user-role.js'), 'user-role.js')
  resolveStoreModules(require('../store/user.js'), 'user.js')
  resolveStoreModules(require('../store/value-list-item.js'), 'value-list-item.js')
  resolveStoreModules(require('../store/value-list.js'), 'value-list.js')

  // If the environment supports hot reloading...

  if (process.client && module.hot) {
    // Whenever any Vuex module is updated...
    module.hot.accept([
      '../store/apollo.js',
      '../store/auth.js',
      '../store/center.js',
      '../store/computed-value.js',
      '../store/index.js',
      '../store/manufacturer.js',
      '../store/medication-category.js',
      '../store/medication.js',
      '../store/patient.js',
      '../store/questionaire.js',
      '../store/sae-report.js',
      '../store/schema-node.js',
      '../store/side-effect-category.js',
      '../store/side-effect.js',
      '../store/study.js',
      '../store/survey-def.js',
      '../store/survey.js',
      '../store/tablet-account.js',
      '../store/user-role.js',
      '../store/user.js',
      '../store/value-list-item.js',
      '../store/value-list.js',
    ], () => {
      // Update `root.modules` with the latest definitions.
      updateModules()
      // Trigger a hot update in the store.
      window.$nuxt.$store.hotUpdate(store)
    })
  }
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
