import { CreateUser, UpdateUser, DeleteUser } from '~/apollo/user.gql';


export const actions = {
    create({ dispatch }, record) {
        return dispatch('apollo/mutate', {
            mutation: CreateUser,
            variables: { record },
        }, { root: true });
    },
    update({ dispatch }, { _id, ...record }) {
        return dispatch('apollo/mutate', {
            mutation: UpdateUser,
            variables: { _id, record },
        }, { root: true });
    },
    delete({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: DeleteUser,
            variables: { _id },
        }, { root: true });
    },
};
