

export default class ValidationError extends Error {
    constructor(error) {
        super(error.message);
        this.errors = {};
        if (error.errors) {
            for (const item of error.errors) {
                this.errors[item.path] = [item.message];
            }
        } else {
            this.errors._self = [error.message];
        }
    }
};
