import { CreateMedicationCategory, UpdateMedicationCategory, DeleteMedicationCategory } from '~/apollo/medication-category.gql';


export const actions = {
    create({ dispatch }, record) {
        return dispatch('apollo/mutate', {
            mutation: CreateMedicationCategory,
            variables: { record },
        }, { root: true });
    },
    update({ dispatch }, { _id, ...record }) {
        return dispatch('apollo/mutate', {
            mutation: UpdateMedicationCategory,
            variables: { _id, record },
        }, { root: true });
    },
    delete({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: DeleteMedicationCategory,
            variables: { _id },
        }, { root: true });
    },
};
