import { RetrySaeReportTask } from '~/apollo/sae-report.gql';


export const actions = {
    retryTask({ dispatch }, input) {
        return dispatch('apollo/mutate', {
            mutation: RetrySaeReportTask,
            variables: { input },
        }, { root: true });
    },
};
