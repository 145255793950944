import { CreateValueListItem, UpdateValueListItem, DeleteValueListItem } from '~/apollo/value-list-item.gql';


export const actions = {
    create({ dispatch }, record) {
        return dispatch('apollo/mutate', {
            mutation: CreateValueListItem,
            variables: { record },
        }, { root: true });
    },
    update({ dispatch }, { _id, ...record }) {
        return dispatch('apollo/mutate', {
            mutation: UpdateValueListItem,
            variables: { _id, record },
        }, { root: true });
    },
    delete({ dispatch }, { _id }) {
        return dispatch('apollo/mutate', {
            mutation: DeleteValueListItem,
            variables: { _id },
        }, { root: true });
    },
};
