import Vue from 'vue';


Vue.filter('username', (user) => {
    if (!user) {
        return 'unbekannt';
    }
    return user.fullName || user.login;
});


Vue.filter('gender', (gender) => {
    switch (gender) {
        case 'male': return 'Männlich';
        case 'female': return 'Weiblich';
    }
    return gender;
});

Vue.filter('saeReportType', (value) => {
    switch (value) {
        case 'first': return 'Erstmeldung';
        case 'follow_up': return 'Folgemeldung';
    }
    return value;
});


Vue.filter('date', (date, config = {}) => {
    return new Date(date).toLocaleDateString('de', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        ...config,
    });
});


const schemaFields = {
    attributes: { label: 'Attribute' },
    condition: { label: 'Bedingung' },
    text: { label: 'Attribute' },
    key: { label: 'Key' },
    widget: { label: 'Widget' },
    options: { label: 'Optionen' },
    suffix: { label: 'Suffix' },
    minValue: { label: 'Mindestwert' },
    maxValue: { label: 'Maximalwert' },
    multiple: { label: 'Mehfachauswahl' },
    subQuestionCondition: { label: 'Bedigung für Unterfragen' },
};


Vue.filter('schemaFieldLabel', (key) => {
    return (schemaFields[key] ?? { label: key }).label;
});


Vue.filter('number', (value, decimalPlaces = null) => {
    return typeof value === 'number'
        ? value.toLocaleString('de-DE', {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        })
        : value;
});
